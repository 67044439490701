// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-ideas-js": () => import("./../../../src/pages/ideas.js" /* webpackChunkName: "component---src-pages-ideas-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-iron-women-js": () => import("./../../../src/pages/iron-women.js" /* webpackChunkName: "component---src-pages-iron-women-js" */),
  "component---src-pages-nandos-js": () => import("./../../../src/pages/nandos.js" /* webpackChunkName: "component---src-pages-nandos-js" */),
  "component---src-pages-o-2-o-retail-js": () => import("./../../../src/pages/o2o-retail.js" /* webpackChunkName: "component---src-pages-o-2-o-retail-js" */),
  "component---src-pages-our-reach-js": () => import("./../../../src/pages/our-reach.js" /* webpackChunkName: "component---src-pages-our-reach-js" */),
  "component---src-pages-our-services-js": () => import("./../../../src/pages/our-services.js" /* webpackChunkName: "component---src-pages-our-services-js" */),
  "component---src-pages-our-work-js": () => import("./../../../src/pages/our-work.js" /* webpackChunkName: "component---src-pages-our-work-js" */),
  "component---src-pages-privacy-notice-js": () => import("./../../../src/pages/privacy-notice.js" /* webpackChunkName: "component---src-pages-privacy-notice-js" */),
  "component---src-pages-snack-revolution-js": () => import("./../../../src/pages/snack-revolution.js" /* webpackChunkName: "component---src-pages-snack-revolution-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */)
}

